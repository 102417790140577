import { createSlice } from "@reduxjs/toolkit";

const initialSummariesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  summaryForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const summariesSlice = createSlice({
  name: "summaries",
  initialState: initialSummariesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSummaryById
    summaryFetched: (state, action) => {
      state.actionsLoading = false;
      state.summaryForPrint = action.payload.summaryForPrint;
      state.error = null;
    },
    // sendMail
    sendMail: (state, action) => {
      state.actionsLoading = false;
      state.sendMail = action.payload.sendMail;
      state.error = null;
    },
    // get report
    reportFetched: (state, action) => {
      state.actionsLoading = false;
      state.report = action.payload.report;
      state.error = null;
    },
    // findSummaries
    summariesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createSummary
    // summaryCreated: (state, action) => {
    //   state.actionsLoading = false;
    //   state.error = null;
    //   state.entities.push(action.payload.summary);
    // },
    // updateSummary
    summaryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.summary.id) {
          return action.payload.summary;
        }
        return entity;
      });
    },
    // deleteSummary
    summaryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteSummaries
    summariesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // summariesUpdateState
    summariesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    //02-02-2021 by preena , for accessory_report
    accessoriesFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.accessories = action.payload.accessoriesArray;
    },
  },
});
