/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function CustomDropdownMenu2({
  naviagate,
  naviagate1,
  naviagate2,
  id,
  role_id,
}) {
  return (
    <>
      {/*begin::Navigation*/}
      <ul className="navi navi-hover">
        <li className="navi-header pb-1">
          <span className="text-primary text-uppercase font-weight-bold font-size-sm">
            Retailer:
          </span>
        </li>
        <li className="navi-item">
          <a onClick={() => naviagate2(id)} className="navi-link">
            <span className="navi-icon">
              <i className="flaticon-eye"></i>
            </span>
            <span className="navi-text">View Retailer</span>
          </a>
        </li>
        <li className="navi-item">
          <a onClick={() => naviagate(id)} className="navi-link">
            <span className="navi-icon">
              <i className="flaticon2-writing"></i>
            </span>
            <span className="navi-text">Edit Retailer</span>
          </a>
        </li>
        {parseInt(role_id) === 3 ? (
          ""
        ) : (
          <li className="navi-item">
            <a onClick={() => naviagate1(id)} className="navi-link">
              <span className="navi-icon">
                <i className="flaticon2-trash"></i>
              </span>
              <span className="navi-text">Delete Retailer</span>
            </a>
          </li>
        )}
      </ul>
      {/*end::Navigation*/}
    </>
  );
}
