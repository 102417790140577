/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  //accessing state from redux
  const { authData } = useSelector((state) => state.auth);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      {authData ? (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          {/*hidden by preena 19-01-2021-for future need  */}
          {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
          {/*end::1 Level*/}

          {/*begin::2 Level*/}

          <li
            className={`menu-item ${getMenuItemActive("/retailers", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/retailers">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group.svg"
                  )}
                />
              </span>
              <span className="menu-text">Retailers</span>
            </NavLink>
          </li>

          {/*end::2 Level*/}

          {/*begin::3 Level*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/sales",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle disabled-link"
              to="/sales"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")}
                />
              </span>
              <span className="menu-text">Sales</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Sales</span>
                  </span>
                </li>

                {/*begin::1 Level*/}
                <li
                  className={`menu-item ${getMenuItemActive("/sales/assigns", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link menu-toggle" to="/sales/assigns">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Assignment Summary</span>
                    <i className="menu-arrow" />
                  </NavLink>
                </li>
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/sales/connections",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/sales/connections"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Connection Summary</span>
                    <i className="menu-arrow" />
                  </NavLink>
                </li>
                {/*end::1 Level*/}

                {/*begin::2 Level*/}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/sales/topups",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/sales/topups">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Topup Summary</span>
                    <i className="menu-arrow" />
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::3 Level*/}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/sales/summaries",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to="/sales/summaries"
                  >
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Accessory Summary</span>
                    <i className="menu-arrow" />
                  </NavLink>
                </li>
                {/*end::3 Level*/}
              </ul>
            </div>
          </li>

          {/*end::3 Level*/}

          {/*begin::4 Level*/}
          {
            parseInt(authData.data[0].role_id) === 1 || parseInt(authData.data[0].role_id) === 2 
            ?
              <li
                className={`menu-item ${getMenuItemActive("/inventories", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/inventories">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Home/Home.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Inventories</span>
                </NavLink>
              </li>
            :
              ""
          }
          
          {/*end::4 Level*/}

          {/*begin::5 Level*/}
          {parseInt(authData.data[0].role_id) === 1 ||
          parseInt(authData.data[0].role_id) === 2 ? (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/masterdata",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/masterdata">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                  />
                </span>
                <span className="menu-text">Master Data</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Master Data</span>
                    </span>
                  </li>

                  {/*begin::1 Level*/}
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/masterdata/companyprofile",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/masterdata/companyprofile"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Company Profile</span>
                      <i className="menu-arrow" />
                    </NavLink>
                  </li>
                  {/*end::1 Level*/}

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/masterdata/networks",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/masterdata/networks"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Network And Commision</span>
                      <i className="menu-arrow" />
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::3 Level*/}
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/masterdata/types",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/masterdata/types"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Retailer Types</span>
                      <i className="menu-arrow" />
                    </NavLink>
                  </li>
                  {/*end::3 Level*/}

                  {/*begin::4 Level*/}
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/masterdata/accessories",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/masterdata/accessories"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Accessories Inventory</span>
                      <i className="menu-arrow" />
                    </NavLink>
                  </li>
                  {/*end::4 Level*/}
                  {/*begin::5 Level*/}
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/masterdata/categories",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/masterdata/categories"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Accessories Types</span>
                      <i className="menu-arrow" />
                    </NavLink>
                  </li>
                  {/*end::5 Level*/}
                </ul>
              </div>
            </li>
          ) : (
            ""
          )}

          {/*end::5 Level*/}

          {/*begin::6 Level*/}
          {parseInt(authData.data[0].role_id) === 1 ||
          parseInt(authData.data[0].role_id) === 2 ? (
            <li
              className={`menu-item ${getMenuItemActive("/users", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/users">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                  />
                </span>
                <span className="menu-text">Users</span>
              </NavLink>
            </li>
          ) : (
            ""
          )}

          {/*end::6 Level*/}
        </ul>
      ) : (
        ""
      )}

      {/* end::Menu Nav */}
    </>
  );
}
