import { createSlice } from "@reduxjs/toolkit";

const initialInventriesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  inventryForEdit: undefined,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const inventriesSlice = createSlice({
  name: "inventries",
  initialState: initialInventriesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getInventryById
    inventryFetched: (state, action) => {
      state.actionsLoading = false;
      state.inventryForEdit = action.payload.inventryForEdit;
      state.error = null;
    },

    // get report
    reportFetched: (state, action) => {
      state.actionsLoading = false;
      state.report = action.payload.report;
      state.error = null;
    },

    // get inventery summery
    inventerySummery: (state, action) => {
      state.listLoading = false;
      state.actionsLoading = false;
      state.inventerySummery = action.payload.summery;
      state.error = null;
    },

    // findInventries
    inventriesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createInventry
    inventryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      //state.entities.push(action.payload.inventry);
      state.listLoading = false;
      state.inventoryUploadStatus = action.payload.inventry;
    },

    // updateInventry
    inventryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.inventry.id) {
          return action.payload.inventry;
        }
        return entity;
      });
    },

    // deleteInventry
    inventryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },

    // deleteInventries
    inventriesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },

    // inventriesUpdateState
    inventriesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },

    //13-01-2021 by preena , for network
    networksFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.retailers = action.payload.retailersArray;
    },
  },
});
