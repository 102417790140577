import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";

const RetailerPage = lazy(() =>
  import("./modules/DataTableComponents/pages/retailerPage")
);
const ConnectionPage = lazy(() =>
  import("./modules/DataTableComponents/pages/connectionPage")
);
const InventoryPage = lazy(() =>
  import("./modules/DataTableComponents/pages/inventoryPage")
);
const TopupPage = lazy(() =>
  import("./modules/DataTableComponents/pages/topupPage")
);
const SummaryPage = lazy(() =>
  import("./modules/DataTableComponents/pages/summaryPage")
);
const UserPage = lazy(() =>
  import("./modules/DataTableComponents/pages/userPage")
);
const AccessoryPage = lazy(() =>
  import("./modules/DataTableComponents/pages/accessoryPage")
);
const CategoryPage = lazy(() =>
  import("./modules/DataTableComponents/pages/categoryPage")
);
const NetworkPage = lazy(() =>
  import("./modules/DataTableComponents/pages/networkPage")
);
const TypePage = lazy(() =>
  import("./modules/DataTableComponents/pages/typePage")
);

const CompanyProfilePage = lazy(() =>
  import("./modules/CompanyProfile/CompanyProfilePage")
);

/* Mapped Sim Report */
const MappedPage = lazy(() =>
  import("./modules/DataTableComponents/pages/assignedPage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/retailers" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/retailers" component={RetailerPage} />
        <Route path="/users" component={UserPage} />
        <Route path="/sales/assigns" component={MappedPage} />
        <Route path="/masterdata/networks" component={NetworkPage} />
        <Route path="/masterdata/types" component={TypePage} />
        <Route path="/masterdata/accessories" component={AccessoryPage} />
        <Route path="/masterdata/categories" component={CategoryPage} />
        <Route
          path="/masterdata/companyprofile"
          component={CompanyProfilePage}
        />
        <Route path="/sales/connections" component={ConnectionPage} />
        <Route path="/inventories" component={InventoryPage} />
        <Route path="/sales/topups" component={TopupPage} />
        <Route path="/sales/summaries" component={SummaryPage} />
      </Switch>
    </Suspense>
  );
}
