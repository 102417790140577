export default [
  {
    id: 1,
    typeRetailer: "Sonni",
    lastName: "Gabotti",
    email: "sgabotti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
  {
    id: 1,
    typeRetailer: "Anne",
    lastName: "botti",
    email: "botti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
  {
    id: 1,
    typeRetailer: "sai",
    lastName: "Gabotti",
    email: "sgabotti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
  {
    id: 1,
    typeRetailer: "Sonni",
    lastName: "Gabotti",
    email: "sgabotti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
  {
    id: 1,
    typeRetailer: "Anne",
    lastName: "botti",
    email: "botti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
  {
    id: 1,
    typeRetailer: "sai",
    lastName: "Gabotti",
    email: "sgabotti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
  {
    id: 1,
    typeRetailer: "Sonni",
    lastName: "Gabotti",
    email: "sgabotti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
  {
    id: 1,
    typeRetailer: "Anne",
    lastName: "botti",
    email: "botti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
  {
    id: 1,
    typeRetailer: "sai",
    lastName: "Gabotti",
    email: "sgabotti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
  {
    id: 1,
    typeRetailer: "Sonni",
    lastName: "Gabotti",
    email: "sgabotti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
  {
    id: 1,
    typeRetailer: "Anne",
    lastName: "botti",
    email: "botti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
  {
    id: 1,
    typeRetailer: "sai",
    lastName: "Gabotti",
    email: "sgabotti0@wsj.com",
    userName: "sgabotti0",
    gender: "Female",
    status: 0,
    dateOfBbirth: "10/14/1950",
    ipAddress: "251.237.126.210",
  },
];
