import axios from "axios";
import { loginURL, retailerURL, forgotPasswordURL } from "../../../api/API";

export const LOGIN_URL = loginURL;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = forgotPasswordURL;

export const ME_URL = retailerURL;

export function login(email, mpwd) {
  return axios.post(LOGIN_URL, { email, mpwd });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.get(`${REQUEST_PASSWORD_URL}?email=${email}`);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
