import typeTableMock from "./typeTableMock";
import MockUtils from "./mock.utils";

export default function mockType(mock) {
  mock.onPost("api/types").reply(({ data }) => {
    const { retailertype } = JSON.parse(data);
    const {
      typeRetailer = "",
      lastName = "",
      email = "",
      userName = "",
      gender = "Female",
      status = 0,
      dateOfBbirth = "01/01/2019",
      ipAddress = "127.0.0.1",
      type = 1,
    } = retailertype;

    const id = generateUserId();
    const newType = {
      id,
      typeRetailer,
      lastName,
      email,
      userName,
      gender,
      status,
      dateOfBbirth,
      ipAddress,
      type,
    };
    typeTableMock.push(newType);
    return [200, { type: newType }];
  });

  mock.onPost("api/types/find").reply((config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdTypes = mockUtils.baseFilter(typeTableMock, queryParams);
    return [200, filterdTypes];
  });

  mock.onPost("api/types/deleteTypes").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = typeTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        typeTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/types/updateStatusForTypes").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    typeTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/types\/\d+/).reply((config) => {
    const id = config.url.match(/api\/types\/(\d+)/)[1];
    const type = typeTableMock.find((el) => el.id === +id);
    if (!type) {
      return [400];
    }

    return [200, type];
  });

  mock.onPut(/api\/types\/\d+/).reply((config) => {
    const id = config.url.match(/api\/types\/(\d+)/)[1];
    const { type } = JSON.parse(config.data);
    const index = typeTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }

    typeTableMock[index] = { ...type };
    return [200];
  });

  mock.onDelete(/api\/types\/\d+/).reply((config) => {
    const id = config.url.match(/api\/types\/(\d+)/)[1];
    const index = typeTableMock.findIndex((el) => el.id === +id);
    typeTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = typeTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
