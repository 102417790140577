import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
// import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
// import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { QuickUserToggler } from "../extras/QuiclUserToggler";

import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "../../../_helpers";
import { NavLink } from "react-router-dom";

export function Topbar() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div className="topbar">
      {/* {layoutProps.viewSearchDisplay && <SearchDropdown />}

      {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />} */}
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1"
            id="kt_quick_search_toggle"
          >
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip  id="search_iccid">Search ICCID</Tooltip>}
              >
              <NavLink to="/retailers/iccidSearch" className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
              </NavLink>
            </OverlayTrigger>
          </div>
        </div>

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
