import { serverURL } from "../app/api/API";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      /*needed 09-01-20 by Preena*/

      config.baseURL = serverURL;

      if (authToken) {
        //we need this
        //comment by Ragavan on 22 Jan 2021
        //config.headers.Authorization = `Bearer ${authToken}`;
        config.headers.token = authToken;
      }
      // console.log(config);
      return config;
    },
    (err) => Promise.reject(err)
  );
}
