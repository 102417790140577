import { createSlice } from "@reduxjs/toolkit";

const initialTopupsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  topupForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const topupsSlice = createSlice({
  name: "topups",
  initialState: initialTopupsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getTopupById
    topupFetched: (state, action) => {
      state.actionsLoading = false;
      state.topupForEdit = action.payload.topupForEdit;
      state.error = null;
    },
    // findTopups
    topupsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createTopup
    topupCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.topupUpload = action.payload.status;
      state.entities.push(action.payload.topup);
    },
    // get report
    reportFetched: (state, action) => {
      state.actionsLoading = false;
      state.report = action.payload.report;
      state.error = null;
    },
    // updateTopup
    topupUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.topup.id) {
          return action.payload.topup;
        }
        return entity;
      });
    },
    // deleteTopup
    topupDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteTopups
    topupsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // topupsUpdateState
    topupsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    //fetch commision report
    commisionsFetched: (state, action) => {
      const { commisionArrayLength, commisionsArray } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.commisions = commisionsArray;
      state.commisionsCount = commisionArrayLength;
    },
  },
});
