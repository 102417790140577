import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { retailersSlice } from "../app/modules/DataTableComponents/_redux/retailers/retailersSlice";
import { connectionsSlice } from "../app/modules/DataTableComponents/_redux/connections/connectionsSlice";
import { inventriesSlice } from "../app/modules/DataTableComponents/_redux/inventries/inventriesSlice";
import { topupsSlice } from "../app/modules/DataTableComponents/_redux/topups/topupsSlice";
import { summariesSlice } from "../app/modules/DataTableComponents/_redux/summaries/summariesSlice";

import { accessoriesSlice } from "../app/modules/DataTableComponents/_redux/accessories/accessoriesSlice";
import { networksSlice } from "../app/modules/DataTableComponents/_redux/networks/networksSlice";
import { typesSlice } from "../app/modules/DataTableComponents/_redux/types/typesSlice";
import { categoriesSlice } from "../app/modules/DataTableComponents/_redux/categories/categoriesSlice";
import { usersSlice } from "../app/modules/DataTableComponents/_redux/users/usersSlice";
import { assignsSlice } from "../app/modules/DataTableComponents/_redux/assigns/assignsSlice";
import { productsSlice } from "../app/modules/DataTableComponents/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/DataTableComponents/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/DataTableComponents/_redux/specifications/specificationsSlice";
import { profilesSlice } from "../app/modules/CompanyProfile/_redux/profile/profileSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  retailers: retailersSlice.reducer,
  profiles: profilesSlice.reducer,
  accessories: accessoriesSlice.reducer,
  categories: categoriesSlice.reducer,
  networks: networksSlice.reducer,
  connections: connectionsSlice.reducer,
  inventries: inventriesSlice.reducer,
  topups: topupsSlice.reducer,
  summaries: summariesSlice.reducer,
  types: typesSlice.reducer,
  users: usersSlice.reducer,
  assigns: assignsSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
