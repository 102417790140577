/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

export function TilesWidget12({
  className,
  iconColor = "success",
  widgetHeight = "130px",
  text,
  icon,
  total
}) {
  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className="card-body">
          <span className={`svg-icon svg-icon-3x svg-icon-${iconColor} d-flex align-items-center`}>
            <SVG
              src={toAbsoluteUrl(icon)}
            />
            <a className="text-dark-75 text-hover-primary font-weight-bolder font-size-lg ml-3 mt-1">{text}</a>
          </span>
          <div className="text-dark font-weight-bolder font-size-h2 mt-3">
           {total}
          </div>
        </div>
      </div>
    </>
  );
}
