import connectionTableMock from "./connectionTableMock";
import MockUtils from "./mock.utils";

export default function mockConnection(mock) {
  mock.onPost("api/connections").reply(({ data }) => {
    const { connection } = JSON.parse(data);
    const {
      connectionName = "",
      connectionCode = "",
      address = "",
      town = "",
      city = "",
      telephoneNumber = "",
      postCode = "",
      connectionType = "",
      contactPerson = "",
    } = connection;

    const id = generateUserId();
    const newConnection = {
      id,
      connectionName,
      connectionCode,
      address,
      town,
      city,
      telephoneNumber,
      postCode,
      connectionType,
      contactPerson,
    };
    connectionTableMock.push(newConnection);
    return [200, { connection: newConnection }];
  });

  mock.onPost("api/connections/find").reply((config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdConnections = mockUtils.baseFilter(
      connectionTableMock,
      queryParams
    );
    return [200, filterdConnections];
  });

  mock.onPost("api/connections/deleteConnections").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = connectionTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        connectionTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/connections/updateStatusForConnections").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    connectionTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/connections\/\d+/).reply((config) => {
    const id = config.url.match(/api\/connections\/(\d+)/)[1];
    const connection = connectionTableMock.find((el) => el.id === +id);
    if (!connection) {
      return [400];
    }

    return [200, connection];
  });

  mock.onPut(/api\/connections\/\d+/).reply((config) => {
    const id = config.url.match(/api\/connections\/(\d+)/)[1];
    const { connection } = JSON.parse(config.data);
    const index = connectionTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }

    connectionTableMock[index] = { ...connection };
    return [200];
  });

  mock.onDelete(/api\/connections\/\d+/).reply((config) => {
    const id = config.url.match(/api\/connections\/(\d+)/)[1];
    const index = connectionTableMock.findIndex((el) => el.id === +id);
    connectionTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = connectionTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
