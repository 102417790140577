export const loginURL = "/api/v1/auth";
export const companyProfileURL = "/api/v1/company";
export const fileUploadURL = "/api/excel/upload";
export const userURL = "api/v1/user";
export const codesURL = "/api/v1/region/";
export const networkURL = "/api/v1/network";
export const retailerURL = "/api/v1/retailer";
export const retailerReportURL = "/api/v1/retailer/report";
export const RETAILERSEARCH_URL = "/api/v1/retailer/iccidSearch";
export const RETAILERSIMASSIGN_URL = "/api/v1/retailer/assignSim";
export const retailerTypeURL = "/api/v1/retailertype";
export const connectionURL = "/api/v1/connection/report";
export const inventryURL = "/api/v1/connection/report";
export const topupURL = "/api/v1/topup/report";
export const commisionCalculateURL = "/api/v1/topup/calculateCommission";
export const commisionActionURL = "/api/v1/topup/payCommission";
export const forgotPasswordURL = "/api/v1/forgotpwdreq/";
export const accessoryURL = "/api/v1/accessory";
export const accessoryTypeURL = "/api/v1/accessorytype";
export const ORDERACCESSORY_URL = "/api/v1/retailer/orderAccessory";
export const ORDERACCESSORY_URL1 = "/api/v1/retailer1/orderAccessory";
export const ORDERSUMMARY_URL = "/api/v1/retailer1/orderSummary";
export const SEND_URL = "/api/v1/accessory/sendmail/";
export const DOWNLOAD_URL = "api/connection/summary";
export const ASSIGNS_URL = "api/v1/assignment";
export const REPORTASSIGNMENTURL = "api/v1/assignment/report";
export const INVENTERYSUMMREY = "/api/v1/inventory/summary";
export const UPDATEICCIDSTATUS = "/api/v1/inventory/is_invalid";

//export const serverURL = "https://dev.lite-telecom.com/";
const url = window.location.href.split("#")[0];
export const serverURL = url;
