// import usersData from "./usersData";
import MockUtils from "./mock.utils";
const usersData = JSON.parse(localStorage.getItem("userData"));
export default function mockUser(mock) {
  mock.onPost("api/users").reply(({ data }) => {
    const { user } = JSON.parse(data);
    const {
      user_name = "",
      mpwd = "",
      mobile = "",
      email = "",
      role = "",
    } = user;

    // const id = generateUserId();
    const newUser = {
      user_name,
      mpwd,
      mobile,
      email,
      role,
    };
    usersData.push(newUser);
    return [200, { user: newUser }];
  });

  mock.onPost("api/users/find").reply((config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdUsers = mockUtils.baseFilter(usersData, queryParams);
    return [200, filterdUsers];
  });

  mock.onPost("api/users/deleteUsers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = usersData.findIndex((el) => el.id === id);
      if (index > -1) {
        usersData.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/users/updateStatusForUsers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    usersData.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  //mock.onGet("api/v1/user").reply(200);

  mock.onGet(/api\/users\/\d+/).reply((config) => {
    const id = config.url.match(/api\/users\/(\d+)/)[1];
    const user = usersData.find((el) => el.id === +id);
    if (!user) {
      return [400];
    }

    return [200, user];
  });

  mock.onPut(/api\/users\/\d+/).reply((config) => {
    const id = config.url.match(/api\/users\/(\d+)/)[1];
    const { user } = JSON.parse(config.data);
    const index = usersData.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }

    usersData[index] = { ...user };
    return [200];
  });

  mock.onDelete(/api\/users\/\d+/).reply((config) => {
    const id = config.url.match(/api\/users\/(\d+)/)[1];
    const index = usersData.findIndex((el) => el.id === +id);
    usersData.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

// function generateUserId() {
//   const ids = usersData.map((el) => el.id);
//   const maxId = Math.max(...ids);
//   return maxId + 1;
// }
