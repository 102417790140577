import { createSlice } from "@reduxjs/toolkit";

const initialRetailersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  typesData: null,
  retailerForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const retailersSlice = createSlice({
  name: "retailers",
  initialState: initialRetailersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    errorCatch: (state, action) => {
      state.searchInfo = action.payload.error.clientMessage;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getRetailerById
    retailerFetched: (state, action) => {
      state.actionsLoading = false;
      state.retailerForEdit = action.payload.retailerForEdit;
      //state.assignInfo = "";
      //state.searchInfo = "";
      state.error = null;
    },
    // findRetailers
    retailersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createRetailer
    retailerCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.retailer);
    },
    // searchRetailer
    retailerSearched: (state, action) => {
      //console.log(action.payload.retailer)
      state.actionsLoading = false;
      state.error = null;
      state.searchInfo = action.payload.retailer;
    },
    // assignRetailer
    retailerAssigned: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.assignInfo = action.payload.retailer;
    },
     // updateRetailer
     retailerAssignmentUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.assignInfoOne = action.payload.retailer;
    },
    // updateRetailer
    updateStatus: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.updateStatus = action.payload.responseData;
    },
    // updateRetailer
    retailerUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.retailer.id) {
          return action.payload.retailer;
        }
        return entity;
      });
    },
    // deleteRetailer
    retailerDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteRetailers
    retailersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // retailersUpdateState
    retailersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },

    // getRetailerTypes
    retailerTypesFetched: (state, action) => {
      const { totalCount, typesData } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.typesData = typesData;
      state.typesCount = totalCount;
    },
    // order accessory to retailer
    accessoryOrder: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.orderDetailes = action.payload.orderInfo;
    },
  },
});
