import { createSlice } from "@reduxjs/toolkit";

const initialAssignsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  assignForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const assignsSlice = createSlice({
  name: "assigns",
  initialState: initialAssignsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getRetailerById
    assignFetched: (state, action) => {
      state.actionsLoading = false;
      state.assignForEdit = action.payload.assignForEdit;
      state.error = null;
    },

    // getRetailerById
    assignmentFetched: (state, action) => {
      state.actionsLoading = false;
      state.assignmentForEdit = action.payload.assignmentForEdit;
      state.error = null;
    },

    //getAllAssigns
    assignsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

      //get sales region code
      codesFetched: (state, action) => {
        const { entities } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.regionCodes = entities;
      },

    // createRetailer
    assignCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.assign);
    },
    // updateRetailer
    assignUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.assign.id) {
          return action.payload.assign;
        }
        return entity;
      });
    },
    // deleteRetailer
    assignDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteAssigns
    assignsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // assignsUpdateState
    assignsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    //getAllUsers
    usersFetched: (state, action) => {
      state.actionsLoading = false;
      state.assignmentUsers = action.payload.assignmentUsers;
      state.error = null;
    },
  },
});
