/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export function BreadCrumbs({ items }) {
  if (!items || !items.length) {
    return "";
  }

  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
      <li className="breadcrumb-item">
        <div>
          <i className="flaticon2-shelter text-muted icon-1x" />
        </div>
      </li>
      {items.map((item, index) => (
        <li key={`bc${index}`} className="breadcrumb-item">
          <div className="text-muted">{item.title}</div>
        </li>
      ))}
    </ul>
  );
}
