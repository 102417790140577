import MockAdapter from "axios-mock-adapter";
import mockAuth from "../../app/modules/Auth/__mocks__/mockAuth";
import mockConnections from "../../app/modules/DataTableComponents/__mocks__/mockConnection";
import mockTopups from "../../app/modules/DataTableComponents/__mocks__/mockTopup";

// import mockCompanies from "../../app/modules/DataTableComponents/__mocks__/mockCompany";
import mockTypes from "../../app/modules/DataTableComponents/__mocks__/mockType";
// // import mockNetworks from "../../app/modules/DataTableComponents/__mocks__/mockNetwork";
import mockUsers from "../../app/modules/DataTableComponents/__mocks__/mockUser";
// import mockProducts from "../../app/modules/DataTableComponents/__mocks__/mockProduct";
// import mockRemarks from "../../app/modules/DataTableComponents/__mocks__/mockRemark";
// import mockSpecifications from "../../app/modules/DataTableComponents/__mocks__/mockSpecification";

export default function mockAxios(axios) {
  const mock = new MockAdapter(axios.create(), { delayResponse: 300 });

  mockAuth(mock);
  mockConnections(mock);
  mockTopups(mock);
  // mockCompanies(mock);
  mockTypes(mock);
  // // mockNetworks(mock);
  mockUsers(mock);
  // mockProducts(mock);
  // mockRemarks(mock);
  // mockSpecifications(mock);

  return mock;
}
